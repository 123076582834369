import React from 'react';
import { Button } from '@material-ui/core';
import HideLocalVideoIcon from './HideLocalVideoIcon';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function HideLocalVideoButton() {
  const { hideLocalVideo, toggleHideLocalVideo } = useVideoContext();

  return (
    <Button onClick={toggleHideLocalVideo} startIcon={<HideLocalVideoIcon closed={hideLocalVideo} />}>
      {hideLocalVideo ? 'Show' : 'Hide'} Self View
    </Button>
  );
}
